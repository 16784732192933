import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import LatestNews from '../components/blocks/LatestNews'
import CurrentPost from '../components/CurrentPost'

const Page = ({ data, pageContext, location }) => {
  // skip first one
const latestPosts = data.latestPosts.nodes.filter( (post, i) => i > 0 )
return (
  <Layout
    page={data.page}
    pageContext={pageContext}
    location={location}
    cta={data?.wp?.callToAction?.cta}
  >
    <CurrentPost node={data.latestPosts.nodes[0]} />
    <Seo title={data.page.title} seo={data.page.seo} />
    <LatestNews
      title="News Archive"
      latestPosts={{ nodes: latestPosts }}
      pageContext={data.pageContext}
    />
  </Layout>
)}

export default Page

export const query = graphql`
  query newsArchive($id: String!) {
    page: wpPage(id: { eq: $id }) {
      pageTemplate
      id
      title
      uri
      slug
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          id
          mediaDetails {
            width
            height
          }
          localFile {
            publicURL
          }
        }
        breadcrumbs {
          text
          url
        }
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    latestPosts: allWpPost(
      sort: { order: DESC, fields: date }
      filter: { status: { eq: "publish" } }
      limit: 20
    ) {
      nodes {
        status
        title
        uri
        content
        news {
          description
          ingress
          columns
          image {
            altText
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 1200
                  base64Width: 240
                  quality: 85
                  toFormat: JPG
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wp {
      callToAction {
        cta {
          btnShow
          btnAltText
          btnSvg {
            localFile {
              publicURL
            }
          }
          btnLink {
            target
            title
            url
          }
          btnFilter {
            ... on WpPage {
              databaseId
            }
          }
          form {
            title
            textBottom
            text
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1920
                    base64Width: 240
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    base64Width: 60
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
